import React, { FunctionComponent } from 'react';

interface ChevronIconProps {
  direction?: 'up' | 'down' | 'left' | 'right';
  color?: string;
  opacity?: string;
}

export const ChevronIcon: FunctionComponent<ChevronIconProps> = ({
  direction = 'down',
  color = '#282829',
  opacity = '0.4',
}) => {
  let rotate = '0';
  switch (direction) {
    case 'up':
      rotate = '180';
      break;
    case 'left':
      rotate = '90';
      break;
    case 'right':
      rotate = '-90';
      break;
    default:
      rotate = '0';
  }
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        transform={`rotate(${rotate} 7 7)`}
        style={{ transition: 'transform 0.2s ease-in-out' }}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.62874 10.6782L13.7485 4.5584C14.0838 4.20211 14.0838 3.6572 13.7485 3.32187C13.4132 2.98654 12.8683 2.98654 12.512 3.32187L7 8.83385L1.48802 3.32187C1.15269 2.98654 0.586826 2.98654 0.251496 3.32187C-0.0838327 3.6572 -0.0838327 4.20211 0.251496 4.5584L6.39222 10.6782C6.72754 11.0135 7.27245 11.0135 7.62874 10.6782Z'
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default ChevronIcon;
