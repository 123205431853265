import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-10px);
    opacity: 0;
  }
`;

const AnimatedContent = styled.div<{ isOpen: boolean }>`
  transition: height 0.5s ease-in-out, transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-10px)')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.5s ease-in-out;
`;

interface HeightBasedCollapsibleProps {
  isOpen: boolean;
  children: React.ReactNode;
  containerProps?: any;
}

export const HeightBasedCollapsible: React.FC<HeightBasedCollapsibleProps> = ({
  isOpen,
  children,
  containerProps,
}) => {
  return (
    <CSSTransition in={isOpen} timeout={500} unmountOnExit>
      <AnimatedContent isOpen={isOpen} {...containerProps}>
        {children}
      </AnimatedContent>
    </CSSTransition>
  );
};
